<template>
    <b-container v-if="isLoaded" fluid class="py-4">
        <b-row v-if="title" class="mb-4">
            <b-col
                class="d-flex flex-column align-items-start justify-content-between flex-grow flex-sm-row align-items-sm-center"
            >
                <h1 class="text-primary mb-0">{{ title }}</h1>
            </b-col>
        </b-row>

        <b-card class="px-3 px-md-5 py-2 py-md-4">
            <div
                class="card-container d-flex align-items-stretch justify-content-between flex-column flex-lg-row"
            >
                <div class="detail-container">
                    <router-view :page-title.sync="title" />
                </div>
                <div class="summary-container">
                    <SummaryCard v-if="hasOrderingPermission" class="summary-card" />
                </div>
            </div>
        </b-card>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>

<script>
import SummaryCard from './components/SummaryCard.vue';
import {mapGetters} from 'vuex';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'ShoppingCart',
    components: {SummaryCard},
    data() {
        return {
            title: this.t('checkout_ShoppingCart'),
            isLoaded: false,
        };
    },
    computed: {
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('cart', ['summary']),
        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE],
            });
        },
    },
    methods: {
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
    },
    async mounted() {
        if (this.hasOrderingPermission) await this.blockingRequest('cart/fetchItems');
        this.isLoaded = true;
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/_screensize.scss';

.card-container {
    gap: 2rem;
}

.detail-container {
    flex-grow: 2;
    max-width: 1000px;
}

@include lg {
    .summary-container {
        flex-grow: 1;
        max-width: 450px;
        min-width: 300px;

        .summary-card {
            position: sticky;
            top: 90px;
        }
    }
}
</style>
